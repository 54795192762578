@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: normal;
  src: url("../public/font-circle.woff") format("woff");
}

body {
  font-family: "Circe";
}